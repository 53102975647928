export const FETCH_POSTINVOICE_INIT = "FETCH_POSTINVOICE_INIT";
export const FETCH_POSTINVOICE_SUCCESS = "FETCH_POSTINVOICE_SUCCESS";
export const FETCH_POSTINVOICE_ERROR = "FETCH_POSTINVOICE_ERROR";

export const FETCH_RECEIVABLE_INIT = "FETCH_RECEIVABLE_INIT";
export const FETCH_RECEIVABLE_SUCCESS = "FETCH_RECEIVABLE_SUCCESS";
export const FETCH_RECEIVABLE_ERROR = "FETCH_RECEIVABLE_ERROR";

export const MOVE_TO_RECEIVABLE_INIT = "MOVE_TO_RECEIVABLE_INIT";
export const MOVE_TO_RECEIVABLE_SUCCESS = "MOVE_TO_RECEIVABLE_SUCCESS";
export const MOVE_TO_RECEIVABLE_ERROR = "MOVE_TO_RECEIVABLE_ERROR";

export const FETCH_CUSTOMER_PAYLIST = "FETCH_CUSTOMER_PAYLIST";
export const FETCH_CUSTOMER_PAYLIST_SUCCESS = "FETCH_CUSTOMER_PAYLIST_SUCCESS";
export const FETCH_CUSTOMER_PAYLIST_ERROR = "FETCH_CUSTOMER_PAYLIST_ERROR";

export const FETCH_ORDERPDF_INIT = "FETCH_ORDERPDF_INIT";
export const FETCH_ORDERPDF_INIT_SUCCESS = "FETCH_ORDERPDF_INIT_SUCCESS";
export const FETCH_ORDERPDF_INIT_ERROR = "FETCH_ORDERPDF_INIT_ERROR";

export const FETCH_ORDERPDFEMAIL_INIT = "FETCH_ORDERPDFEMAIL_INIT";
export const FETCH_ORDERPDFEMAIL_INIT_SUCCESS =
  "FETCH_ORDERPDFEMAIL_INIT_SUCCESS";
export const FETCH_ORDERPDFEMAIL_ERROR = "FETCH_ORDERPDFEMAIL_ERROR";

export const FETCH_RECEIVABLESIGNLEVIEW_INIT =
  "FETCH_RECEIVABLESIGNLEVIEW_INIT";
export const FETCH_RECEIVABLESIGNLEVIEW_INIT_SUCCESS =
  "FETCH_RECEIVABLESIGNLEVIEW_INIT_SUCCESS";
export const FETCH_RECEIVABLESIGNLEVIEW_ERROR =
  "FETCH_RECEIVABLESIGNLEVIEW_ERROR";

export const FETCH_REVENUEEMAIL_INIT = "FETCH_REVENUEEMAIL_INIT";
export const FETCH_REVENUEEMAIL_INIT_SUCCESS =
  "FETCH_REVENUEEMAIL_INIT_SUCCESS";
export const FETCH_REVENUEEMAIL_ERROR = "FETCH_REVENUEEMAIL_ERROR";

export const FETCH_RECEIVABLE_ADD_INIT = "FETCH_RECEIVABLE_ADD_INIT";
export const FETCH_RECEIVABLE_ADD_SUCCESS = "FETCH_RECEIVABLE_ADD_SUCCESS";
export const FETCH_RECEIVABLE_ADD_ERROR = "FETCH_RECEIVABLE_ADD_ERROR";

export const FETCH_RECEIVABLE_DELETE_INIT = "FETCH_RECEIVABLE_DELETE_INIT";
export const FETCH_RECEIVABLE_DELETE_SUCCESS =
  "FETCH_RECEIVABLE_DELETE_SUCCESS";
export const FETCH_RECEIVABLE_DELETE_ERROR = "FETCH_RECEIVABLE_DELETE_ERROR";

export const FETCH_RECEIVABLE_EDIT_INIT = "FETCH_RECEIVABLE_EDIT_INIT";
export const FETCH_RECEIVABLE_EDIT_SUCCESS = "FETCH_RECEIVABLE_EDIT_SUCCESS";
export const FETCH_RECEIVABLE_EDIT_ERROR = "FETCH_RECEIVABLE_EDIT_ERROR";

export const FETCH_POSTEMAIL_INIT = "FETCH_POSTEMAIL_INIT";
export const FETCH_POSTEMAIL_INIT_SUCCESS = "FETCH_POSTEMAIL_INIT_SUCCESS";
export const FETCH_POSTEMAIL_ERROR = "FETCH_POSTEMAIL_ERROR";
