export const FETCH_VENDOR_PAYLIST = "FETCH_VENDOR_PAYLIST";
export const FETCH_VENDOR_PAYLIST_SUCCESS = "FETCH_VENDOR_PAYLIST_SUCCESS";
export const FETCH_VENDOR_PAYLIST_ERROR = "FETCH_VENDOR_PAYLIST_ERROR";

export const FETCH_EMPLOYEE_PAYLIST = "FETCH_EMPLOYEE_PAYLIST";
export const FETCH_EMPLOYEE_PAYLIST_SUCCESS = "FETCH_EMPLOYEE_PAYLIST_SUCCESS";
export const FETCH_EMPLOYEE_PAYLIST_ERROR = "FETCH_EMPLOYEE_PAYLIST_ERROR";

export const FETCH_OWNEROPERATOR_PAYLIST = "FETCH_OWNEROPERATOR_PAYLIST";
export const FETCH_OWNEROPERATOR_PAYLIST_SUCCESS =
  "FETCH_OWNEROPERATOR_PAYLIST_SUCCESS";
export const FETCH_OWNEROPERATOR_PAYLIST_ERROR =
  "FETCH_OWNEROPERATOR_PAYLIST_ERROR";

export const PAY_VENDOR = "PAY_VENDOR";
export const PAY_VENDOR_SUCCESS = "PAY_VENDOR_SUCCESS";
export const PAY_VENDOR_ERROR = "PAY_VENDOR_ERROR";

export const PAY_EMPLOYEE = "PAY_EMPLOYEE";
export const PAY_EMPLOYEE_SUCCESS = "PAY_EMPLOYEE_SUCCESS";
export const PAY_EMPLOYEE_ERROR = "PAY_EMPLOYEE_ERROR";

export const PAY_OWNEROPERATOR = "PAY_OWNEROPERATOR";
export const PAY_OWNEROPERATOR_SUCCESS = "PAY_OWNEROPERATOR_SUCCESS";
export const PAY_OWNEROPERATOR_ERROR = "PAY_OWNEROPERATOR_ERROR";

export const PAID_LIST = "PAID_LIST";
export const PAID_LIST_SUCCESS = "PAID_LIST_SUCCESS";
export const PAID_LIST_ERROR = "PAID_LIST_ERROR";

export const SINGLE_PAID_LIST = "SINGLE_PAID_LIST";
export const SINGLE_PAID_LIST_SUCCESS = "SINGLE_PAID_LIST_SUCCESS";
export const SINGLE_PAID_LIST_ERROR = "SINGLE_PAID_LIST_ERROR";

export const EXPENSEPDF_EMAIL = "EXPENSEPDF_EMAIL";
export const EXPENSEPDF_EMAIL_SUCCESS = "EXPENSEPDF_EMAIL_SUCCESS";
export const EXPENSEPDF_EMAIL_ERROR = "EXPENSEPDF_EMAIL_ERROR";

export const FETCH_UNKNOWN_PAYLIST = "FETCH_UNKNOWN_PAYLIST";
export const FETCH_UNKNOWN_PAYLIST_SUCCESS = "FETCH_UNKNOWN_PAYLIST_SUCCESS";
export const FETCH_UNKNOWN_PAYLIST_ERROR = "FETCH_UNKNOWN_PAYLIST_ERROR";
