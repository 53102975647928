import {
  FileTextOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  AppstoreFilled,
  BankFilled,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";

const driverNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Dashboard",
    icon: AppstoreFilled,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-dashboard",
        path: `${APP_PREFIX_PATH}/apps/project`,
        title: "Records",
        icon: AppstoreFilled,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-dashboard-employee",
            parentKey: "apps-dashboard",
            path: `${APP_PREFIX_PATH}/employee/list`,
            title: "Employee",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dashboard-customer",
            parentKey: "apps-dashboard",
            path: `${APP_PREFIX_PATH}/customer/list`,
            title: "Customer",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dashboard-vendor",
            parentKey: "apps-dashboard",
            path: `${APP_PREFIX_PATH}/vendor/list`,
            title: "Vendor",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dashboard-equipment",
            parentKey: "apps-dashboard",
            path: `${APP_PREFIX_PATH}/equipment/list`,
            title: "Equipment",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dashboard-location",
            parentKey: "apps-location",
            path: `${APP_PREFIX_PATH}/location/list`,
            title: "Location",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "components-operation",
        title: "Operations",
        icon: InfoCircleOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-dashboard-quote",
            parentKey: "components-operation",
            path: `${APP_PREFIX_PATH}/quote/list`,
            title: "Quote",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-dashboard-order",
            parentKey: "components-operation",
            path: `${APP_PREFIX_PATH}/order/list`,
            title: "Orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "components-dispatch",
            parentKey: "components-operation",
            path: `${APP_PREFIX_PATH}/dispatch/list`,
            title: "Dispatch",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "components-shipment",
            parentKey: "components-operation",
            path: `${APP_PREFIX_PATH}/shipment/list`,
            title: "Shipment",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "components-equipmentstatus",
            parentKey: "components-operation",
            path: `${APP_PREFIX_PATH}/equipment-status`,
            title: "Equipment Status",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "live-tracking",
        parentKey: "live-tracking",
        path: `${APP_PREFIX_PATH}/live-tracking`,
        title: "LiveTracking",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "components-revenueaccounts",
        title: "Revenue Accounts",
        icon: BankFilled,
        breadcrumb: true,
        submenu: [
          {
            key: "components-postinvoice",
            parentKey: "components-revenueaccounts",
            path: `${APP_PREFIX_PATH}/post-invoice/list`,
            title: "Process Customer Pay",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "components-receivable",
            parentKey: "components-revenueaccounts",
            path: `${APP_PREFIX_PATH}/receivable`,
            title: "Paid Invoices",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "expense-accounts",
        title: "Expense Accounts",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "processvendorpay",
            parentKey: "expense-accounts",
            path: `${APP_PREFIX_PATH}/process-vendor-pay`,
            title: "Process Vendor Pay",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "processemployeepay",
            parentKey: "expense-accounts",
            path: `${APP_PREFIX_PATH}/process-employee-pay`,
            title: "Process Employee Pay",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "processowneroperatorpay",
            parentKey: "expense-accounts",
            path: `${APP_PREFIX_PATH}/process-owner-or-operator`,
            title: "Process Owner/Operator Pay",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "processunknownpay",
            parentKey: "expense-accounts",
            path: `${APP_PREFIX_PATH}/process-unknown-pay`,
            title: "Process Unknown Pay",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "payable",
            parentKey: "expense-accounts",
            path: `${APP_PREFIX_PATH}/payable`,
            title: "Paid Bills",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const superNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Super Admin",
    icon: AppstoreFilled,
    breadcrumb: false,
    submenu: [
      {
        key: "company",
        path: `${APP_PREFIX_PATH}/company`,
        title: "Company",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //     key: 'settings',
      //     path: `${APP_PREFIX_PATH}/admin-settings`,
      //     title: 'Settings',
      //     icon: InfoCircleOutlined,
      //     breadcrumb: false,
      //     submenu: []
      // }
    ],
  },
];

const navigationConfig =
  APP_PREFIX_PATH === "/driver" ? [...driverNavTree] : [...superNavTree];

export default navigationConfig;
