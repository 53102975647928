import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/RevenueConstants";
import RevenueService from "../../services/RevenueService";

export function* FetchPostInvoiceSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchPostInvoice, payload);
    yield put({ type: constant.FETCH_POSTINVOICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_POSTINVOICE_ERROR, error });
  }
}
export function* FetchReceivableSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchReceivable, payload);
    yield put({ type: constant.FETCH_RECEIVABLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_RECEIVABLE_ERROR, error });
  }
}
export function* MoveReceivableSaga(payload) {
  try {
    const response = yield call(RevenueService.MoveReceivable, payload);
    yield put({ type: constant.MOVE_TO_RECEIVABLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MOVE_TO_RECEIVABLE_ERROR, error });
  }
}
export function* FetchCustomerPaySaga(payload) {
  try {
    const response = yield call(RevenueService.FetchCustomerPays, payload);
    yield put({ type: constant.FETCH_CUSTOMER_PAYLIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_CUSTOMER_PAYLIST_ERROR, error });
  }
}
export function* FetchOrderPdfSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchOrderPdf, payload);
    yield put({ type: constant.FETCH_ORDERPDF_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_ORDERPDF_INIT_ERROR, error });
  }
}
export function* FetchOrderEmailSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchOrderEmail, payload);
    yield put({ type: constant.FETCH_ORDERPDFEMAIL_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_ORDERPDFEMAIL_ERROR, error });
  }
}
export function* FetchReceivableSingleViewSage(payload) {
  try {
    const response = yield call(
      RevenueService.FetchReceivableSingleView,
      payload
    );
    yield put({
      type: constant.FETCH_RECEIVABLESIGNLEVIEW_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.FETCH_RECEIVABLESIGNLEVIEW_ERROR, error });
  }
}
export function* FetchRevenueEmailSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchRevenueEmail, payload);
    yield put({ type: constant.FETCH_REVENUEEMAIL_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_REVENUEEMAIL_ERROR, error });
  }
}

export function* FetchReceivableAddSaga(payload) {
  try {
    const response = yield call(
      RevenueService.FetchReceivableAdd,
      payload.formData
    );
    yield put({ type: constant.FETCH_RECEIVABLE_ADD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_RECEIVABLE_ADD_ERROR, error });
  }
}

export function* DeleteRevenuesSaga(payload) {
  try {
    const response = yield call(RevenueService.DeleteRevenues, payload);
    yield put({ type: constant.FETCH_RECEIVABLE_DELETE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_RECEIVABLE_DELETE_ERROR, error });
  }
}

export function* UpdateRevenuesSaga(payload) {
  try {
    const response = yield call(
      RevenueService.UpdateRevenues,
      payload.formData,
      payload.id
    );
    yield put({ type: constant.FETCH_RECEIVABLE_EDIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_RECEIVABLE_EDIT_ERROR, error });
  }
}

export function* FetchPostEmailSaga(payload) {
  try {
    const response = yield call(RevenueService.FetchPostEmail, payload);
    yield put({ type: constant.FETCH_POSTEMAIL_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_POSTEMAIL_ERROR, error });
  }
}
export default function* RevenueSaga() {
  yield takeLatest(constant.FETCH_POSTINVOICE_INIT, FetchPostInvoiceSaga);
  yield takeLatest(constant.MOVE_TO_RECEIVABLE_INIT, MoveReceivableSaga);
  yield takeLatest(constant.FETCH_RECEIVABLE_INIT, FetchReceivableSaga);
  yield takeLatest(constant.FETCH_CUSTOMER_PAYLIST, FetchCustomerPaySaga);
  yield takeLatest(constant.FETCH_ORDERPDF_INIT, FetchOrderPdfSaga);
  yield takeLatest(constant.FETCH_ORDERPDFEMAIL_INIT, FetchOrderEmailSaga);
  yield takeLatest(
    constant.FETCH_RECEIVABLESIGNLEVIEW_INIT,
    FetchReceivableSingleViewSage
  );
  yield takeLatest(constant.FETCH_REVENUEEMAIL_INIT, FetchRevenueEmailSaga);
  yield takeLatest(constant.FETCH_RECEIVABLE_ADD_INIT, FetchReceivableAddSaga);
  yield takeLatest(constant.FETCH_RECEIVABLE_DELETE_INIT, DeleteRevenuesSaga);
  yield takeLatest(constant.FETCH_RECEIVABLE_EDIT_INIT, UpdateRevenuesSaga);
  yield takeLatest(constant.FETCH_POSTEMAIL_INIT, FetchPostEmailSaga);
}
