import * as constant from "../constants/DispatchConstants";

const initialState = {
  dispatch_list: [],
  loading: false,
  error: null,
  orderstatus_update: null,
  subform_success: null,
  dispatch_count_list: null,
  shipment_status_update: null,
  dispatch_completion: null,
  addexpensesuccess: null,
  expense_delete_success: null,
  document_data: null,
  dispatch_by_id: null,
  update_dispatch_by_id: null,
  move_existing: false,
  existing_loading: false,
  driver_edit_success: false,
  dispatch_view: null,
  postorder_trip: null,
  trip_emailsend: null,
  load_emailsend: null,
  get_expense: null,
  update_expense: null,
};

const DispatchReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case constant.FETCH_DISPATCH_INIT:
    case constant.VIEW_DISPATCH_INIT:
    case constant.DELETE_DISPATCH_INIT:
    case constant.DISPATCH_CONTACT_INIT:
    case constant.ACCOUNT_REP_CREATE_INIT:
    case constant.SALES_REP_CREATE_INIT:
    case constant.DISPATCH_ORDER_STATUS_INIT:
    case constant.FETCH_DISPATCH_COUNTS_INIT:
    case constant.DISPATCH_ORDER_SHIPMENT_STATUS_INIT:
    case constant.DISPATCH_COMPLETION_UPDATE_INIT:
    case constant.FETCH_ADDEXPENSE_INIT:
    case constant.DELETE_EXPENSE_INIT:
    case constant.CREATE_DISPATCH_DOCUMENT:
    case constant.FETCH_DISPATCH_DATA_INIT:
    case constant.UPDATE_DISPATCH_DATA_INIT:
    case constant.UPDATE_TRAILER_DRIVER_INIT:
    case constant.FETCH_TRIP_EMAIL_INIT:
    case constant.FETCH_TRIP_EMAIL_SEND_INIT:
    case constant.FETCH_LOAD_EMAIL_SEND_INIT:
    case constant.EXPENSE_INIT:
    case constant.EXPENSE_UPDATE_INIT:
      return {
        ...state,
        loading: true,
        create_success: false,
        update_success: false,
        // driver_status:true,
        // driver_edit_success:false
      };

    case constant.FETCH_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatch_list: response.data,
      };
    case constant.DISPATCH_COMPLETION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatch_completion: response,
      };
    case constant.FETCH_DISPATCH_COUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatch_count_list: response.data,
      };
    case constant.VIEW_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatch_view: response.data,
      };
    case constant.ACCOUNT_REP_CREATE_SUCCESS:
    case constant.DISPATCH_CONTACT_SUCCESS:
    case constant.SALES_REP_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        subform_success: response,
      };
    case constant.UPDATE_DISPATCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        move_existing: response,
      };
    case constant.DELETE_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        update_success: response.status,
      };
    case constant.DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expense_delete_success: response,
      };
    case constant.DISPATCH_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderstatus_update: response,
      };
    case constant.DISPATCH_ORDER_SHIPMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipment_status_update: response,
      };
    case constant.FETCH_ADDEXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        addexpensesuccess: response,
      };
    case constant.CREATE_DISPATCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        document_data: response,
        document_update: response,
      };
    case constant.FETCH_DISPATCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatch_by_id: response.data,
      };
    case constant.UPDATE_TRAILER_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        driver_edit_success: response,
      };
    case constant.CLEAR_TRAILER:
      return {
        ...state,
        loading: false,
        driver_edit_success: "",
      };
    case constant.FETCH_TRIP_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        postorder_trip: response.data,
      };
    case constant.FETCH_TRIP_EMAIL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        trip_emailsend: response,
      };

    case constant.FETCH_LOAD_EMAIL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        load_emailsend: response,
      };
    case constant.EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        get_expense: response.data,
      };
    case constant.EXPENSE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        update_expense: response,
      };
    case constant.FETCH_DISPATCH_ERROR:
    case constant.VIEW_DISPATCH_ERROR:
    case constant.DELETE_DISPATCH_ERROR:
    case constant.DISPATCH_CONTACT_ERROR:
    case constant.ACCOUNT_REP_CREATE_ERROR:
    case constant.SALES_REP_CREATE_ERROR:
    case constant.DISPATCH_ORDER_STATUS_ERROR:
    case constant.DISPATCH_ORDER_SHIPMENT_STATUS_ERROR:
    case constant.DISPATCH_COMPLETION_UPDATE_ERROR:
    case constant.FETCH_ADDEXPENSE_ERROR:
    case constant.DELETE_EXPENSE_ERROR:
    case constant.CREATE_DISPATCH_DOCUMENT_ERROR:
    case constant.FETCH_DISPATCH_DATA_ERROR:
    case constant.UPDATE_DISPATCH_DATA_ERROR:
    case constant.UPDATE_TRAILER_DRIVER_ERROR:
    case constant.FETCH_TRIP_EMAIL_ERROR:
    case constant.FETCH_TRIP_EMAIL_SEND_ERROR:
    case constant.FETCH_LOAD_EMAIL_SEND_ERROR:
    case constant.EXPENSE_ERROR:
    case constant.EXPENSE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: response,
      };
    default:
      return state;
  }
};

export default DispatchReducer;
