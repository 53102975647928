import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/ExpenseConstants";
import ExpenseService from "../../services/ExpenseService";

export function* FetchVendorPaySaga(payload) {
  try {
    const response = yield call(ExpenseService.FetchVendorPays, payload);
    yield put({ type: constant.FETCH_VENDOR_PAYLIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_VENDOR_PAYLIST_ERROR, error });
  }
}
export function* FetchEmployeePaySaga(payload) {
  try {
    const response = yield call(ExpenseService.FetchEmployeePays, payload);
    yield put({ type: constant.FETCH_EMPLOYEE_PAYLIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_EMPLOYEE_PAYLIST_ERROR, error });
  }
}
export function* FetchOwnerOperatorPaySaga(payload) {
  try {
    const response = yield call(ExpenseService.FetchOwnerOperatorPays, payload);
    yield put({ type: constant.FETCH_OWNEROPERATOR_PAYLIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_OWNEROPERATOR_PAYLIST_ERROR, error });
  }
}
export function* PayVendorSaga(payload) {
  try {
    const response = yield call(ExpenseService.PayVendorApi, payload.formData);
    yield put({ type: constant.PAY_VENDOR_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PAY_VENDOR_ERROR, error });
  }
}
export function* PayEmployeeSaga(payload) {
  try {
    const response = yield call(
      ExpenseService.PayEmployeeApi,
      payload.formData
    );
    yield put({ type: constant.PAY_EMPLOYEE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PAY_EMPLOYEE_ERROR, error });
  }
}
export function* PayOwnerOperatorSaga(payload) {
  try {
    const response = yield call(
      ExpenseService.PayOwnerOperatorApi,
      payload.formData
    );
    yield put({ type: constant.PAY_OWNEROPERATOR_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PAY_OWNEROPERATOR_ERROR, error });
  }
}
export function* paidListSaga(payload) {
  try {
    const response = yield call(ExpenseService.PaidListApi, payload);
    yield put({ type: constant.PAID_LIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PAID_LIST_ERROR, error });
  }
}
export function* singlepaidListSaga(payload) {
  try {
    const response = yield call(ExpenseService.SinglePayList, payload);
    yield put({ type: constant.SINGLE_PAID_LIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.SINGLE_PAID_LIST_ERROR, error });
  }
}
export function* ExpensePdfmailSend(payload) {
  try {
    const response = yield call(ExpenseService.ExpensePdfMailSend, payload);
    yield put({ type: constant.EXPENSEPDF_EMAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EXPENSEPDF_EMAIL_ERROR, error });
  }
}

export function* FetchUnknownPaySaga(payload) {
  try {
    const response = yield call(ExpenseService.FetchUnknownPays, payload);
    yield put({ type: constant.FETCH_UNKNOWN_PAYLIST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_UNKNOWN_PAYLIST_ERROR, error });
  }
}

export default function* VendorSaga() {
  yield takeLatest(constant.FETCH_VENDOR_PAYLIST, FetchVendorPaySaga);
  yield takeLatest(constant.FETCH_EMPLOYEE_PAYLIST, FetchEmployeePaySaga);
  yield takeLatest(
    constant.FETCH_OWNEROPERATOR_PAYLIST,
    FetchOwnerOperatorPaySaga
  );
  yield takeLatest(constant.PAY_VENDOR, PayVendorSaga);
  yield takeLatest(constant.PAY_EMPLOYEE, PayEmployeeSaga);
  yield takeLatest(constant.PAY_OWNEROPERATOR, PayOwnerOperatorSaga);
  yield takeLatest(constant.PAID_LIST, paidListSaga);
  yield takeLatest(constant.SINGLE_PAID_LIST, singlepaidListSaga);
  yield takeLatest(constant.EXPENSEPDF_EMAIL, ExpensePdfmailSend);
  yield takeLatest(constant.FETCH_UNKNOWN_PAYLIST, FetchUnknownPaySaga);
}
