import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
const PageNotFound = React.lazy(() => import("./notfound"));

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {APP_PREFIX_PATH === "/driver" ? (
          <Redirect
            exact
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/dashboard`}
          />
        ) : (
          <Redirect
            exact
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/company`}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company`}
          component={lazy(() => import(`./company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customer`}
          component={lazy(() => import(`./customer`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quote`}
          component={lazy(() => import(`./quote`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/order`}
          component={lazy(() => import(`./order`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/employee`}
          component={lazy(() => import(`./employee`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/equipment`}
          component={lazy(() => import(`./equipment`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/location`}
          component={lazy(() => import(`./location`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/vendor`}
          component={lazy(() => import(`./vendor`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/live-tracking`}
          component={lazy(() => import(`./live-tracking`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dispatch`}
          component={lazy(() => import(`./dispatch`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/shipment`}
          component={lazy(() => import(`./shipment`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/equipment-status`}
          component={lazy(() => import(`./equipment-status`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/receivable`}
          component={lazy(() => import(`./receivable`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/post-invoice`}
          component={lazy(() => import(`./post-invoice`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/payable`}
          component={lazy(() => import(`./payable`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/post-bills`}
          component={lazy(() => import(`./post-bills`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/process-vendor-pay`}
          component={lazy(() => import(`./process-vendor-pay`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/process-unknown-pay`}
          component={lazy(() => import(`./process-unknown-pay`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/process-employee-pay`}
          component={lazy(() => import(`./process-employee-pay`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/process-owner-or-operator`}
          component={lazy(() => import(`./process-owner-or-operator`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/admin-settings`}
          component={lazy(() => import(`./admin-settings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/configuration`}
          component={lazy(() => import(`./configuration`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route path="*" component={PageNotFound} />

        {APP_PREFIX_PATH === "/driver" ? (
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/dashboard`}
          />
        ) : (
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/company`}
          />
        )}
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
